import {SliderComponent} from "../SliderDefault/SliderComponent";

export default class SliderEnvironmentComponent extends SliderComponent {

  constructor(element, options) {
    options = {
      mode: 'carousel',
      controlsPosition: "top",
      navPosition: "bottom",
      responsive: {
        782: {
          disable: true
        }
      }
    };

    super(element, options);
  }
}

window.pew.addRegistryEntry({key: 'slider-environment', domSelector: '.wp-block-group.engagements', classDef: SliderEnvironmentComponent});
