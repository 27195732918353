import {PewComponent} from "../../../assets/raw/js/components/pew-component";
import {create} from "./cocoen.esm.js";

export class BeforeAfterComponent extends PewComponent {

  init() {
    create(this.element.find('.cocoen')[0]);
  }

}

window.pew.addRegistryEntry({key: 'before-after-component', domSelector: '.before-after-component', classDef: BeforeAfterComponent});
