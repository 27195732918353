export default class ToggleContentViaFilterComponent {
  constructor(element, options) {
    this.element = (element instanceof jQuery) ? element : $(element);

    this.options = {};
    this.setOptions(options);

    this.debug = false;

    this.openFirstPanel = this.element.data('open-first-panel');
    this.openEveryPanel = this.element.data('open-every-panel');
    this.onlyOneOpenedPanelAllowed = this.element.data('only-one-opened-panel-allowed');

    this.$triggers = this.element.find('.wwp-innerblock-content [data-trigger]');

    this.init();
  }

  setOptions(options) {
    this.options = Object.assign(this.options, options);
  }

  init() {
    this.log('scanning', this.element);

    this.addEventListeners();
    if (this.openFirstPanel) {
      this.$triggers.first().trigger('click');
    } else if (this.openEveryPanel) {
      this.$triggers.each((i, trigger) => {
        let $thisTrigger = $(trigger);
        if (typeof ($thisTrigger.data('default-state-hidden')) === 'undefined') {
          $thisTrigger.trigger('click');
        }
      });
    }
  }

  addEventListeners() {
    this.$triggers.click((e) => {
      const $trigger = $(e.currentTarget);
      // Avoid page reload
      if ($trigger.is('a')) {
        e.preventDefault();
      }

      const $parent = $trigger.closest('.wwp-innerblock-content');
      const $target = $parent.find('[data-content]');
      const index = this.$triggers.index(e.currentTarget);

      if ($target.length > 0) {
        if (this.onlyOneOpenedPanelAllowed) {
          if (!$trigger.hasClass('active')) {
            this.$triggers.removeClass('active');
            $trigger.addClass('active');
            let contentPartAttribute = $target.attr('data-content-part-attribute') || '';
            this.element.find('.content-part')
              .html($target.html())
              .attr('data-loaded-part', index)
              .attr('data-content-part-attribute', contentPartAttribute)
            ;
          }
        } else {
          const contentTargetSelector = `#tcvf-${index}-content`;
          const $element = $(`<div>${$target.html()}</div>`);
          $element.attr('id', contentTargetSelector.replace('#', ''));

          if (this.element.find('.content-part').find(contentTargetSelector).length > 0) {
            $trigger.removeClass('active');
            this.element.find('.content-part').find(contentTargetSelector).remove();
          } else {
            $trigger.addClass('active');
            this.element.find('.content-part').append($element);
          }
        }
      } else {
        this.error('Invalid pane. No [data-trigger] or [data-content] found inside the pane', $innerBlock);
      }
    });
  }

  log(msg) {
    if (this.debug) {
      console.log('[tcvf] ', ...arguments);
    }
  }

  error(msg) {
    if (this.debug) {
      console.error('[tcvf] ', ...arguments);
    }
  }

}
