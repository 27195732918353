import {PewComponent} from "../../../assets/raw/js/components/pew-component";

import {gsap} from "gsap";

const {ScrollTrigger} = require("gsap.ScrollTrigger");
gsap.registerPlugin(ScrollTrigger);

export default class ScrollNav extends PewComponent {
  init() {
    this.registerScroll();
  }

  registerScroll() {
    //smooth scroll to target on link click + dynamically change nav active state
    const $links = this.element.find('a');
    if ($links.length) {
      $links.each((i, link) => {
        let trgt = link.href.split('#')[1],
          $trgtElement = $('#' + trgt);
        if ($trgtElement.length) {
          $trgtElement.data('linked_link', $(link));
          this.createScrollTrigger($trgtElement);
        }
      });
    }

    //delegate li click to link;
    this.element.find('li').on('click', (e) => {
      $(e.currentTarget).find('>a').trigger('click');
    });
  }

  createScrollTrigger($trgtElement) {
    ScrollTrigger.create({
      trigger: $trgtElement,
      start: "top center",
      end: "bottom center",
      onToggle: self => self.isActive && this.setActive($trgtElement)
    });
  }

  setActive($trgtElement) {
    this.element.find('li').removeClass('active');
    $trgtElement.data('linked_link').parent().addClass('active');
  }
}

window.pew.addRegistryEntry({key: 'nav-panel', domSelector: '.navigation-wrapper', classDef: ScrollNav});

