import {PewComponent} from "../../../assets/raw/js/components/pew-component";

export class MobileMenuComponent extends PewComponent {
  init() {
    this.registerSummaryToggler();
  }

  registerSummaryToggler() {
    this.element.find('button').on('click',(e)=>{
      this.element.find('.navigation-wrapper').slideToggle();
      this.element.toggleClass('menu-open');
    });
  }
}

window.pew.addRegistryEntry({
  key: 'menu-wrapper',
  domSelector: '.menu-wrapper',
  classDef: MobileMenuComponent
});
